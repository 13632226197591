
export const navToRbacPermissionNameMapping = {
  "dashboards": ["vnr.dashboards"]
}

type NavKey = keyof typeof navToRbacPermissionNameMapping;

export enum AccessTypes {
  WRITE = "write",
  READ = "read",
  NO_ACCESS = "no-access"
}

//
// FOR FUTURE USE
// This routine gets the access type based on the permission sets
//
export const getRbacAccessType = (key: NavKey, authData: any): AccessTypes => {
  const permissionSets = navToRbacPermissionNameMapping[key];

  if (!permissionSets || permissionSets.length === 0) {
      return AccessTypes.NO_ACCESS; //no entry for the nav key, so assume it's not visible
  }

  const accessTypes = permissionSets.map(pSet => {
      return authData?.access?.checkPermissionSet(pSet);
  })

  if (accessTypes.includes("write")) {
      return AccessTypes.WRITE;
  }
  if (accessTypes.includes("read")) {
      return AccessTypes.READ;
  }
  return AccessTypes.NO_ACCESS;
}

//
// FOR FUTURE USE
// This routine decides if a given nav menu should be visible or not based on the rbac
//
export const hasRBACAccess = (key: NavKey, authData: any): boolean => {
  const accessType = getRbacAccessType(key, authData);
  return accessType !== AccessTypes.NO_ACCESS;
}

//
// TEMP FIX to block VNR pages for users that have any role against IOT Security.
// If the user has any additional roles outside of IOT Security then
// their access to the VNR pages will not be blocked.
//
export const hasIOTSecurityRole = (authData: any): boolean => {
  //debugger;
  const access = authData?.access;
  if (!Array.isArray(access) || access.length === 0) {
      return false;
  }

  if (access.length > 2) {
      return false;
  }

  const allAppsRoles = access.find(a => a?.app_id === "")?.roleNames || []
  const hasOnlyBaseRole = allAppsRoles.length === 1 && allAppsRoles[0] === "base"

  const iotAppRoles = access.find(a => a?.app_id === "zingbox")?.roleNames || []
  const hasIotRole = iotAppRoles.length > 0

  if ((allAppsRoles.length === 0 || hasOnlyBaseRole) && hasIotRole) {
      return true
  }
  return false;
};

//
// TEMP FIX to block VNR pages (except Log Viewer) for users that have any role against SLS app.
// If the user has any additional roles outside of SLS then
// their access to the VNR pages will not be blocked.
//
export const hasSLSRole = (authData: any): boolean => {
  //debugger;
  const access = authData?.access;
  if (!Array.isArray(access) || access.length === 0) {
      return false;
  }

  if (access.length > 2) {
      return false;
  }

  const allAppsRoles = access.find(a => a?.app_id === "")?.roleNames || []
  const hasOnlyBaseRole = allAppsRoles.length === 1 && allAppsRoles[0] === "base"

  const slsAppRoles = access.find(a => a?.app_id === "logging_service")?.roleNames || []
  const hasSLSRole = slsAppRoles.length > 0

  if ((allAppsRoles.length === 0 || hasOnlyBaseRole) && hasSLSRole) {
      return true
  }
  return false;
};

